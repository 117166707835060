import { render, staticRenderFns } from "./Survival.vue?vue&type=template&id=e7362cf0&scoped=true&"
import script from "./Survival.vue?vue&type=script&lang=js&"
export * from "./Survival.vue?vue&type=script&lang=js&"
import style0 from "./Survival.vue?vue&type=style&index=0&id=e7362cf0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7362cf0",
  null
  
)

export default component.exports